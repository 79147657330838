import RecentProjects from "../../components/recentProjects";
import Wrapper from "../../components/wrapper/wrapper";
import s from "./homepage.module.scss";
import Jumbo from "./img/jumbo.jpg";
import About from "./img/about.jpg";
import ImageLoader from "../../components/imageLoader";

export default () => {
  return (
    <>
      <div className={s.jumboSplit}>
        <ImageLoader src={Jumbo} className={s.jumboImg} />
        <div className={s.jumboContent}>
          <div className={s.subtext}>welcome</div>
          <div className={s.jumboTitle}>CREATING SPACES FOR BETTER LIVING</div>
          <div className={s.jumboSubHeader}>
            Let us bring your ideas to life!
          </div>
        </div>
      </div>
      <Wrapper>
        <h2>Recent Projects</h2>
        <RecentProjects />
        <h2>About Us</h2>
        <div className={s.aboutGrid}>
          <ImageLoader src={About} alt="" className={s.aboutImage} />
          <div className={s.content}>
            <div className={s.item}>
              <div className={s.header}>Our Mission</div>
              Our passion is helping to create a warm and inviting space through
              careful space planning and selection of suitable materials. We
              will listen to you and create a home that suits you in terms of
              style and practicality.
            </div>
            <div className={s.item}>
              <div className={s.header}>Our Approach</div>
              Communication is key. We're here for whatever you need. We'll work
              together with you as a team and keep in constant contact until
              your dream is realized!
            </div>
            <div className={s.item}>
              <div className={s.header}>Our Team</div>
              We work with an extensive team of suppliers and contractors to
              ensure that the process is smooth and seamless.
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};
