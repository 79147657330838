import classNames from "classnames";
import { useState } from "react";
import s from "./imageLoader.module.scss";

export default ({
  src,
  className,
  alt,
  lazy,
}: {
  src: string;
  className?: string;
  alt?: string;
  lazy?: boolean;
}) => {
  const [loading, setLoading] = useState(true);

  const setLoaded = () => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  return (
    <div
      className={classNames(
        s.imageContainer,
        { [s.loading]: loading },
        className
      )}
    >
      <img
        alt={alt}
        src={src}
        className={s.image}
        loading={lazy ? "lazy" : "eager"}
        onLoad={setLoaded}
      />
    </div>
  );
};
