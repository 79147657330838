import s from './review.module.scss'

type PropsType = {
  data: {
    title: string
    message: string
    author: string
  }
  handleReadMore: (e: {
    title: string
    message: string
    author: string
  }) => void
}

export default ({ data, handleReadMore }: PropsType) => {
  const { title, message, author } = data

  return (
    <div className={s.review}>
      <div className={s.title}>{title}</div>
      <div
        className={s.message}
        dangerouslySetInnerHTML={{
          __html: message.replaceAll('\n', '<p></p>'),
        }}
      />
      <div className={s.readMore} onClick={() => handleReadMore(data)}>
        Read more...
      </div>
      <div className={s.author}>- {author}</div>
    </div>
  )
}
