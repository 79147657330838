import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import SwiperProms, { Lazy, Navigation, Pagination } from 'swiper'
import { Swiper } from 'swiper/react'
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg'
import 'swiper/css'
import 'swiper/css/pagination'

import s from './swiper.module.scss'
import getWidth from '../../utils/getWidth'

export default ({
  children,
  className,
  dots = false,
}: {
  children: React.ReactNode
  className?: string
  dots?: boolean
}) => {
  const backArrow = useRef<HTMLDivElement>(null)
  const nextArrow = useRef<HTMLDivElement>(null)
  const [swiper, setSwiper] = useState<SwiperProms>()
  const width = getWidth()

  useEffect(() => {
    if (!swiper || !nextArrow.current) return
  }, [swiper])

  const getGap = () => {
    if (width > 768) return 30
    if (width > 600) return 20
    else return 10
  }
  if (!children) return <></>

  return (
    <div className={s.wrapper}>
      <div className={s.arrow} ref={backArrow}>
        <Arrow />
      </div>
      <div className={classNames(s.arrow, s.next)} ref={nextArrow}>
        <Arrow className={s.nextArrow} />
      </div>
      <Swiper
        className={classNames(s.swiper, className)}
        direction="horizontal"
        modules={[Navigation, Lazy, Pagination]}
        lazy={{ enabled: true, loadPrevNext: true }}
        spaceBetween={getGap()}
        navigation={{
          prevEl: backArrow.current,
          nextEl: nextArrow.current,
          enabled: true,
        }}
        pagination={{
          enabled: dots && width < 768,
          bulletClass: s.dot,
          bulletActiveClass: s.active,
          horizontalClass: s.dotContainer,
        }}
        slidesPerView={'auto'}
        loop
        rewind
        onSwiper={(swiper) => setSwiper(swiper)}
        children={children}
      />
    </div>
  )
}
