import { SwiperSlide } from "swiper/react";
import "react-image-lightbox/style.css";
import { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import Swiper from "../swiper";
import ImageLoader from "../imageLoader";

import s from "./recentProjects.module.scss";

export default () => {
  const [images, setImages] = useState<Array<string>>([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const arr = [];
    for (let index = 1; index <= 42; index++) {
      arr.push(`/recent-projects/project-${index}.jpeg`);
    }
    setImages(arr.sort(() => Math.random() - 0.5));
  }, []);

  const openLightbox = (index: number) => {
    setIndex(index);
    setLightboxOpen(true);
  };

  return (
    <>
      {lightboxOpen && (
        <Lightbox
          enableZoom={false}
          mainSrc={images[index]}
          wrapperClassName={s.images}
          animationDisabled
          imagePadding={50}
          onImageLoad={() => {
            window.dispatchEvent(new Event("resize"));
          }}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        />
      )}
      {Boolean(images.length) && (
        <Swiper>
          {images.map((e, count) => {
            return (
              <SwiperSlide
                key={count}
                className={s.slide}
                onClick={() => openLightbox(count)}
              >
                <ImageLoader src={e} className={s.loader} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
};
