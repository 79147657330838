import classnames from 'classnames'
import { Link, NavLink } from 'react-router-dom'
import { useState, useEffect } from 'react'
import s from './navbar.module.scss'
import { ReactComponent as Logo } from './img/logo.svg'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

export default () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const history = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      setMenuOpen(false)
    }, 300)
  }, [history])

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : ''
  }, [menuOpen])

  return (
    <div className={s.nav}>
      <div className={s.wrapper}>
        <div className={s.container}>
          <Link to={'/'} className={s.logo}>
            <Logo className={s.logo} />
          </Link>
          {menuOpen ? (
            <svg
              onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
              className={s.hamburger}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                fill="#000"
                d="M13.46 12L19 17.54V19h-1.46L12 13.46 6.46 19H5v-1.46L10.54 12 5 6.46V5h1.46L12 10.54 17.54 5H19v1.46L13.46 12z"
              ></path>
            </svg>
          ) : (
            <svg
              className={s.hamburger}
              onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="none"
            >
              <path
                fill="#000"
                d="M3.75 22.5V20h22.5v2.5H3.75zm0-6.25v-2.5h22.5v2.5H3.75zm0-6.25V7.5h22.5V10H3.75z"
              ></path>
            </svg>
          )}
        </div>
        <div className={classNames(s.links, { [s.open]: menuOpen })}>
          <NavLink
            className={({ isActive }) =>
              classnames(s.navLink, { [s.active]: isActive })
            }
            to={''}
          >
            Home
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              classnames(s.navLink, { [s.active]: isActive })
            }
            to={'what-we-do'}
          >
            What we do
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              classnames(s.navLink, { [s.active]: isActive })
            }
            to={'contact-us'}
          >
            Contact us
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              classnames(s.navLink, { [s.active]: isActive })
            }
            to={'what-they-say'}
          >
            What they say
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              classnames(s.navLink, { [s.active]: isActive })
            }
            to={'top-tips'}
          >
            Top tips
          </NavLink>
        </div>
      </div>
    </div>
  )
}
