import { useEffect, useState } from "react";
import Jumbotron from "../../components/jumbotron";
import ReviewBox from "../../components/reviewBox";
import Wrapper from "../../components/wrapper/wrapper";
import Banner from "./img/banner.jpg";
import { reviews } from "./reviews";
import s from "./whatTheySay.module.scss";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import classNames from "classnames";

export default () => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlay, setOverlay] = useState({
    title: "",
    message: "",
    author: "",
  });

  const handleReadMore = (data: {
    title: string;
    message: string;
    author: string;
  }) => {
    document.body.style.overflow = "hidden";
    setOverlayOpen(true);
    setOverlay(data);
  };

  const handleClose = () => {
    setOverlayOpen(false);
    document.body.style.overflow = "";

    setTimeout(() => {
      setOverlay({
        title: "",
        message: "",
        author: "",
      });
    }, 300);
  };

  useEffect(() => {
    let listener: { (e: any): void; (this: Window, ev: KeyboardEvent): any };

    document.addEventListener(
      "keydown",
      (listener = (e) => {
        if (e.key === "Escape") handleClose();
      })
    );

    return () => document.removeEventListener("keydown", listener);
  }, []);

  return (
    <>
      <div
        className={classNames(s.overlayWrapper, { [s.active]: overlayOpen })}
        style={{ height: window.innerHeight }}
      >
        <div className={s.overlay} onClick={handleClose} />
        <div className={s.popup}>
          <Cross className={s.close} onClick={handleClose} />
          <div className={s.title}>{overlay.title}</div>
          <div
            className={s.message}
            dangerouslySetInnerHTML={{
              __html: overlay.message.replaceAll("\n", "<p></p>"),
            }}
          />
          <div className={s.author}>{overlay.author}</div>
        </div>
      </div>
      <Jumbotron image={Banner} />
      <Wrapper className={s.wrapper}>
        <h2>What clients say...</h2>
        <div className={s.grid}>
          {reviews.map((review) => (
            <ReviewBox
              key={review.title}
              handleReadMore={handleReadMore}
              data={review}
            />
          ))}
        </div>
      </Wrapper>
    </>
  );
};
