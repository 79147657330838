

export default [
    {
        before: "/beforeAfter/before1.jpg",
        after: "/beforeAfter/after1.jpg",
    },
    {
        before: "/beforeAfter/before2.jpg",
        after: "/beforeAfter/after2.jpg",
    },
    {
        before: "/beforeAfter/before3.jpg",
        after: "/beforeAfter/after3.jpg",
    },
    {
        before: "/beforeAfter/before4.jpg",
        after: "/beforeAfter/after4.jpg",
    },
    {
        before: "/beforeAfter/before5.jpg",
        after: "/beforeAfter/after5.jpg",
    },
    {
        before: "/beforeAfter/before6.jpg",
        after: "/beforeAfter/after6.jpg",
    },
]