import { useEffect, useState } from "react"

export default () => {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        setWidth(window.innerWidth)
        window.addEventListener("resize", () => setWidth(window.innerWidth))
    }, [])

    return width
}