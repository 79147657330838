import s from "./footer.module.scss";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import Wrapper from "../wrapper/wrapper";
import getWidth from "../../utils/getWidth";

export default () => {
  return (
    <div className={s.footer}>
      <Wrapper className={s.wrapper}>
        <div className={s.text}>
          COPYRIGHT © 2022 FREUD INTERIORS
          {getWidth() > 600 ? <> - </> : <br />}
          ALL RIGHTS RESERVED.
        </div>
        <a
          href="https://www.instagram.com/freudinteriors"
          target={"_blank"}
          rel={"noreferrer"}
        >
          <Instagram />
        </a>
      </Wrapper>
    </div>
  );
};
