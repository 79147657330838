export const reviews: {
  title: string;
  author: string;
  message: string;
}[] = [
    {
      title: "THERAPY CLINIC RA'ANANA",
      author: 'SH',
      message:
        'Stephanie knows how to get things done- she has a great eye for design, knows how to source products and knows how to work within both a budget and a timeline. I am extremely happy with the finished product and have no hesitation recommending her.',
    },
    {
      title: "RA'ANANA KITCHEN RENOVATION",
      message:
        'Stephanie worked with us to design and build a new kitchen. She was absolutely fantastic- provided great advice and was so efficient. The vendors she brought in were all highly reliable and provided a top quality service. Would highly recommend working with Stephanie- you won’t regret it!',
      author: 'FB',
    },
    {
      title: "RA'ANANA TAMA38 APARTMENT",
      message:
        'Building your first home is exciting, but also quite scary and overwhelming at times! Stephanie made the process a pleasure, ensuring that we always had advice and guidance from floor planning to purchasing and installation. Her industry knowledge, keen eye for design, and Israeli “chutspa “ really saved us during this process. I’d highly recommend',
      author: 'MDS',
    },
    {
      title: 'TEL MOND RENOVATION',
      message:
        "Stephanie helped us design our home from start to finish. We needed guidance both with design and also with how to make it happen. She came with us to various show rooms and shops, drew up the design plans and worked with our contractor to make those designs come to life. Two years later and I still can't believe we live here. Stephanie has a great eye and was a pleasure to work with. I would recommend Stephanie to anyone looking to remodel/redesign.",
      author: 'EZ',
    },
    {
      title: "RA'ANANA NEW BUILD",
      message: `Stephanie Freud is a confident, creative and passionate interior designer, with an eye for detail, innovative ideas, combined with the practical ability to implement them. Perhaps more important as far as we were concerned, she listened to our needs.\nShe is skilful and talented, and able to work within any budget. Stephanie has great local and national contacts, so we know we are getting the best materials and products at realistic prices. She was also there every step of the way from her designs to the building, inspecting the constructions to make sure they were to our specifications.\nStephanie Freud was flexible and adaptable and plugged in to the latest design styles and ideas, and a pleasure to work with. I have no hesitation in recommending her for projects large and small.`,
      author: 'CG',
    }, {
      title: 'TWO ACCESSIBLE KABLAN APARTMENTS',
      message: 'We bought two Tama 38 apartments that were mirror images of each other in the same project. They had to look and feel different, and one had to be wheelchair accessible. Stephanie was AMAZING during the entire process. The apartments look completely different, in color and feel, and both are wheelchair accessible. She worked with the builder and made sure that everything was completed to her satisfaction. One example, she had the builder move a huge water pipe because it was a few centimeters in the wrong place. Stephanie held my hand during the entire stressful process. She even advised me where to purchase appliances, lighting, and furniture.',
      author: 'JF'
    }
  ]
