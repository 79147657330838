import s from "./whatWeDo.module.scss";

import Banner from "./img/banner.jpg";
import Build from "./img/build.jpg";
import Consult from "./img/consult.jpg";
import Renovations from "./img/renovations.jpg";
import Wrapper from "../../components/wrapper/wrapper";
import classNames from "classnames";
import Jumbotron from "../../components/jumbotron";
import ImageLoader from "../../components/imageLoader";
import Swiper from "../../components/swiper";
import images from "./beforeAfter";
import BeforeAfter from "../../components/beforeAfter";
import { SwiperSlide } from "swiper/react";

export default () => {
  return (
    <>
      <Jumbotron image={Banner} />
      <Wrapper className={s.grid}>
        <div className={s.gridRow}>
          <ImageLoader src={Build} className={s.image} />
          <div className={s.content}>
            <div className={s.title}>New build</div>
            <ul>
              <li>Space Planning</li>
              <li>Material selections</li>
              <li>Electrical and Plumbing plans</li>
              <li>Kitchen & Bathroom Design</li>
              <li>Project coordination with building company</li>
              <li>Furniture selection</li>
              <li>Home styling</li>
            </ul>
          </div>
        </div>
        <div className={classNames(s.gridRow, s.reverse)}>
          <div className={s.content}>
            <div className={s.title}>Renovations</div>
            <ul>
              <li>Demolition and building plans</li>
              <li>Flooring selection</li>
              <li>Kitchen Planning and design</li>
              <li>Bathroom Planning and design</li>
              <li>Lighting selection</li>
              <li>Surfaces & Fixtures</li>
            </ul>
          </div>
          <ImageLoader src={Renovations} alt="" className={s.image} />
        </div>
        <div className={s.gridRow}>
          <ImageLoader src={Consult} alt="" className={s.image} />
          <div className={s.content}>
            <div className={s.title}>Interior Design Consult</div>
            <ul>
              <li>Furniture selection</li>
              <li>Rugs & Pillows</li>
              <li>Paint colors</li>
              <li>Lighting upgrades</li>
              <li>Window dressings</li>
            </ul>
          </div>
        </div>
        <h2 className={s.beforeTitle}>Before and After</h2>
        {Boolean(images.length) && (
          <Swiper className={s.swiper} dots>
            {images.map((img, count) => {
              return (
                <SwiperSlide>
                  <BeforeAfter {...img} key={count} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </Wrapper>
    </>
  );
};
