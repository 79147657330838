import Jumbotron from '../../components/jumbotron'
import Wrapper from '../../components/wrapper/wrapper'
import Banner from './img/banner.jpg'
import s from './tips.module.scss'

export default () => {
  return (
    <>
      <Jumbotron image={Banner} />
      <Wrapper className={s.wrapper}>
        <div className={s.content}>
          <h2>HOW TO ACHIEVE A STRESS-FREE KITCHEN RENOVATION</h2>
          <div className={s.subheader}>
            Create your perfect kitchen with these tips to guide you smoothly
            through your project.
          </div>
          For many of us, the kitchen is the hub of the home, the place where we
          spend most of our time. If that’s not the case and you’d like it to
          be, but you don’t quite like your kitchen enough, why not think about
          transforming it? After all, if you end up spending the majority of
          your time in there, it will be well worth it. Here are some simple
          tips to get you on your way.
          <p></p>
          <div className={s.subtitle}>Measure accurately</div>
          The first thing you need to do is measure your current kitchen and
          dining space. Use metric measurements (metres) and include all doors,
          windows, fixed furniture, fuse boxes and the like.
          <p></p>
          If you’re not adept at using a tape measure, leave this to your
          kitchen designer – it’s important to get it right. “Measure twice, cut
          once” is the golden rule, and that might often mean getting a second
          opinion from whoever will be designing or fitting your kitchen.
          <p></p>
          <div className={s.subtitle}>Research a range of kitchens</div>
          The choice of where to source your kitchen is a very personal one, and
          a big decision for your home. Assemble an ideabook of your favourite
          kitchens, and ask for advice from friends and relatives who’ve had
          work done. Meet a kitchen designer or design and fit company, and
          trust your instincts – the company you click with will most likely be
          the best fit for you.
          <p></p>
          <div className={s.subtitle}>Get the design right</div>
          Discuss your ideal kitchen with your chosen kitchen company or
          designer. Give them your plans and allow them to make suggestions,
          even if you already have an idea of what you want. They’ll most likely
          have been in the business for a while, so may have some clever ideas
          you hadn’t considered.
          <p></p>
          Don’t be afraid to challenge their suggestions, though. Remember, only
          you know the nuances of your home and preferences. Your designer will
          probably welcome this input, and enjoy the challenge of finding a
          solution that works.
          <p></p>
          <div className={s.subtitle}>Cost it out</div>
          Ask your kitchen designer to cost your design. Request a detailed
          breakdown, so you can see which items will cost the most. This will
          enable you to see where you might need to trim.
          <p></p>
          Remember, there might be extra costs for removing any machinery,
          adding electrics, plumbing, tiling, flooring and painting. Your
          designer should list all of these on a spreadsheet and get quotes for
          everything, including materials.
          <p></p>
          <div className={s.subtitle}>Agree a schedule</div>
          Your kitchen designer should be able to manage the project, but if
          you’re managing it yourself, you’ll need to set the schedule for the
          works. How long will each trade take? Which trades can be overlapped?
          Are there any items that need time for delivery? Add all of these to a
          spreadsheet with dates of completion, and agree it with everyone
          involved.
          <p></p>
          Talk to all your suppliers and tradespeople to ensure you know how the
          project will run. Agree all dates by email or text, so you have
          written confirmation. Alternatively, hire a project manager to deal
          with all the details of the renovation for you.
          <p></p>
          <div className={s.subtitle}>Stay on the job</div>
          If you’re managing the project, once it begins, be sure to engage with
          all the tradespeople and suppliers. Communication is the key to a
          smooth-running project.
          <p></p>
          If anyone’s late or there’s an issue on site, let all the relevant
          tradespeople know – they will appreciate someone looking after the
          project. If they arrive on the agreed date and nothing is ready or the
          site is disorganised, they might be too busy to reschedule.
          <p></p>
          <div className={s.subtitle}>Protect everything</div>
          As most of the products being fitted will be part of the finished
          scheme, make sure your contractors are protecting surfaces while the
          work progresses. Of particular importance are floors, tiles, windows
          and worktops. Covering items in dust covers or some old bed sheets
          will do the job nicely.
          <p></p>
          <div className={s.subtitle}>Embrace lists !!!!</div>
          As you approach the end of your renovation, you’ll find most things
          are complete, but a lot of small jobs are still left to be finished.
          Ask your designer to make a list of all of these, with the name of the
          relevant tradesperson next to each item, and group all of them
          together by name. When you or your designer ring each tradesperson,
          you’ll have a full list of remaining items, so you can agree a date
          for completion of everything in one go.
          <p></p>
          <div className={s.subtitle}>Test fittings and appliances</div>
          Check to make sure everything is working properly before you end the
          project. Inspect the oven, taps, hob, fridge, bins, cabinet carcasses,
          doors, lights, sockets, extractor, boiler, dishwasher and washing
          machine to ensure they all work properly. File the instructions and
          warranty documents for each somewhere safe.
          <p></p>
          <div className={s.subtitle}>Relax and enjoy</div>
          Take a break and enjoy what you’ve created. Have some family or
          friends over to celebrate. You’ve made it!
        </div>
      </Wrapper>
    </>
  )
}
