import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import ContactUs from "./pages/contactUs";
import Homepage from "./pages/homepage";
import TopTips from "./pages/topTips";
import WhatTheySay from "./pages/whatTheySay";
import WhatWeDo from "./pages/whatWeDo";
import reportWebVitals from "./reportWebVitals";
import "./style.module.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/what-we-do" element={<WhatWeDo />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/what-they-say" element={<WhatTheySay />} />
          <Route path="/top-tips" element={<TopTips />} />
          <Route path="*" element={<Homepage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
