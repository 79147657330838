import Jumbotron from "../../components/jumbotron";
import Jumbo from "./img/jumbo.jpg";
import s from "./contact.module.scss";
import Wrapper from "../../components/wrapper/wrapper";
import classNames from "classnames";
import { useState } from "react";
import { ReactComponent as Whatsapp } from "../../assets/icons/whatsapp.svg";
import { ReactComponent as Tick } from "../../assets/icons/tick.svg";
import emailjs from "@emailjs/browser";

export default () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");

  const validateEmail = (e: string) => {
    setError("");
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
      setError("Please enter a valid email");
    }
  };

  const handleSubmit = () => {
    if (!formData.email || !formData.message || !formData.name) return;
    setLoading(true);
    emailjs
      .send(
        "service_esvdxnn",
        "template_ufummkm",
        {
          name: formData.name,
          email_address: formData.email,
          message: formData.message,
        },
        "l6gaJ1avTeiWNaZdr"
      )
      .then((e) => {
        setLoading(false);
        setIsSubmitted(true);
      });
  };

  return (
    <>
      <Jumbotron image={Jumbo} />
      <Wrapper className={s.wrapper}>
        <div className={s.container}>
          <div className={s.text}>
            <div className={s.textWrapper}>
              <div className={s.title}>We can help!</div>
              <div className={s.section}>
                <div className={s.smallTitle}>Planning a renovation?</div>
                Whether it is small or large send us a message, and we'll get
                back to you with answers soon.
              </div>
              <div className={s.section}>
                <div className={s.linkSection}>
                  Email:&nbsp;
                  <a href="mailto:stephaniefreud@icloud.com">
                    stephaniefreud@icloud.com
                  </a>
                </div>
                <div className={s.linkSection}>
                  Instagram:&nbsp;
                  <a
                    href="https://www.instagram.com/freudinteriors"
                    target={"_blank"}
                  >
                    @freudinteriors
                  </a>
                </div>
              </div>
              <div className={s.section}>
                <div className={s.smallTitle}>Hours</div>
                Sunday - Thursday: 0800 - 1800
                <br />
                Friday - Saturday: Closed
              </div>
              <div className={s.section}>
                <div className={s.smallTitle}>Location</div>
                Ra'anana, Israel
              </div>
            </div>
            <a
              href="https://wa.me/972546429064"
              target={"_blank"}
              className={s.button}
            >
              <Whatsapp /> Message us on Whatsapp
            </a>
          </div>
          {isSubmitted ? (
            <div className={s.submitted}>
              <div className={s.icon}>
                <Tick />
              </div>
              <div className={classNames(s.title, s.lower)}>
                Thank you for submitting!
              </div>
              <div className={s.textWrapper}>We will get back to you soon!</div>
            </div>
          ) : (
            <form className={s.form}>
              <div className={s.title}>Get a quote</div>
              <div className={s.formRow}>
                <label htmlFor="name" className={s.label}>
                  Name *
                </label>
                <input
                  type="text"
                  id="name"
                  className={s.input}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  value={formData.name}
                />
              </div>
              <div className={s.formRow}>
                {error && <div className={s.error}>{error}</div>}
                <label htmlFor="email" className={s.label}>
                  Email *
                </label>
                <input
                  type="text"
                  id="email"
                  className={classNames(s.input, { [s.errorForm]: error })}
                  onInput={(e) => {
                    setFormData({
                      ...formData,
                      email: (e.target as HTMLInputElement).value,
                    });
                  }}
                  onChange={(e) => validateEmail(e.target.value)}
                  value={formData.email}
                />
              </div>
              <div className={s.formRow}>
                <label htmlFor="message" className={s.label}>
                  Tell us more about your project *
                </label>
                <textarea
                  id="message"
                  className={s.textarea}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                  value={formData.message}
                />
              </div>
              <div className="buttonGrid">
                <div
                  onClick={handleSubmit}
                  className={classNames(s.button, {
                    [s.disabled]:
                      !formData.email ||
                      !formData.message ||
                      !formData.name ||
                      error ||
                      loading,
                  })}
                >
                  {loading ? "Sending..." : "Send"}
                </div>
              </div>
            </form>
          )}
        </div>
      </Wrapper>
    </>
  );
};
