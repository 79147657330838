import ImageLoader from "../imageLoader";
import s from "./jumbo.module.scss";

type PropsType = {
  image: string;
};

export default ({ image }: PropsType) => (
  <ImageLoader src={image} className={s.jumbo} lazy={false} />
);
