import classNames from 'classnames'
import React from 'react'
import s from './wrapper.module.scss'

export default ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => <div className={classNames(s.wrapper, className)}>{children}</div>
