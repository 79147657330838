import ImageLoader from "../imageLoader";
import s from "./beforeAfter.module.scss";

type PropsType = {
  before: string;
  after: string;
};

export default ({ before, after }: PropsType) => {
  return (
    <div className={s.slide}>
      <div className={s.side}>
        <div className={s.label}>Before</div>
        <ImageLoader className={s.image} src={before} />
      </div>
      <div className={s.side}>
        <div className={s.label}>After</div>
        <ImageLoader className={s.image} src={after} />
      </div>
    </div>
  );
};
